import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link"]

  onClick(event) {
    const checked = this.element.querySelectorAll('tbody input[type=checkbox]:checked')
    const uri = this.linkTarget.dataset.url.split("?")
    const params = new URLSearchParams(uri.length > 1 ? uri.pop() : "")
    checked.forEach(el => params.append("ids[]", el.value))

    $("#modal-stimulus").find(".modal-content").load(`${uri[0]}?${params.toString()}`, function() {
      $("#modal-stimulus").modal("show")
    })
  }
}
