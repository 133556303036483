import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['warnings', 'cardType', 'warningElement']

  connect(){
    this.initialCardType = this.cardTypeTarget.value
    this.isNewForm = $(this.cardTypeTarget).parents('form').hasClass('new_person')
    $('.select2').each((idx, el) => {
      $(el).on('select2:select', (e) => {
        let event = new Event('change', { bubbles: true })
        el.dispatchEvent(event);
      });
    })
  }

  onCardTypeChanged() {
    if(this.hasWarningElementTarget){
      if (!this.checkLockerCard()) {
        const warning = $(this.warningsTarget).data('card-type-changed')
        this.printWarning(warning)
      } else {
        $(this.warningElementTarget).html('')
      }
    }
  }

  checkLockerCard(){
    let types = this.cardTypeTarget.dataset.lockerCardType.replace(/"/g,"").replace("[","").replace("]","").replace(" ","").split(",")
    for (var i in types) {
      if ((types[i] == 'mifare_and_hid'
        && (this.cardTypeTarget.value == 'mifare_classic_csn'
        || this.cardTypeTarget.value == 'mifare_classic_employee_id'
        || this.cardTypeTarget.value == 'hid_csn'
        || this.cardTypeTarget.value == 'desfire_csn'))
        || (types[i] == 'mifare'
        && (this.cardTypeTarget.value == 'mifare_classic_csn'
        || this.cardTypeTarget.value == 'mifare_classic_employee_id'
        || this.cardTypeTarget.value == 'desfire_csn'))
        || (types[i] == 'hid'
        && (this.cardTypeTarget.value == 'hid_csn'
        || this.cardTypeTarget.value == 'desfire_csn'))
        || (types[i] == 'no_nfc'
        && this.cardTypeTarget.value == 'desfire_csn')
        ){
      } else{
        return false
      }
    }
    return true
  }
  printWarning(warning) {
    if (this.isNewForm === false) {
      let badge = $('<div class="badge badge-danger d-block mb-2" />')
      badge.html(warning)
      $(this.warningElementTarget).html(badge)
    }
  }
}
