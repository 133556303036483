import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["link", "userIds", "selectedUsers", "selectedLockers", "lockerIds"]

  ////
  // add user for allocate
  addUser(e) {
    const uri = this.linkTarget.href.split("?")
    const params = new URLSearchParams(uri.length > 1 ? uri.pop() : "")
    const userId = e.target.value
    const availableLockers = new Number($("#available-lockers").text());
    let userIds = (this.userIdsTarget.dataset.userIds || "").split(",").filter(e =>  e);
    params.delete("user_ids[]")

    if (e.target.checked) {
      userIds.push(userId)
    } else {
      userIds = userIds.filter(id => userId != id)
    }

    this.userIdsTarget.dataset.userIds = userIds.filter(id => id != "")
    userIds = userIds.filter(id => id != "")
    userIds.forEach(id => params.append("user_ids[]", id))
    this.linkTarget.href = `${uri.pop()}?${params.toString()}`

    if(userIds.length === 0) {
      $(this.linkTarget).addClass("d-none")
    } else {
      $(this.linkTarget).removeClass("d-none")
    }
    $(this.selectedUsersTarget).html(`Selected Users (${userIds.length})`)
  }

  addLocker(e) {
    const uri = this.linkTarget.href.split("?")
    const params = new URLSearchParams(uri.length > 1 ? uri.pop() : "")
    const selectedUserCount = new Number($("#selected-users").text());
    const lockerId = e.target.value;
    let lockerIds = (this.lockerIdsTarget.dataset.lockerIds || "").split(",").filter(e =>  e);
    lockerIds = [...new Set(lockerIds)]

    params.delete("locker_ids[]")

    if (e.target.checked) {
      lockerIds.push(lockerId);
      if (lockerIds.length <= selectedUserCount) {
        lockerIds.push(lockerId);
      } else {
        e.target.checked = false;
        alert("You are selected more than "+selectedUserCount+" selected users.");
      }
    } else {
      lockerIds = lockerIds.filter(id => lockerId != id);
    }
    lockerIds = [...new Set(lockerIds.filter(id => id != ""))]

    $(".alert-success").addClass("d-none");
    if (lockerIds.length < selectedUserCount) {
      $(".alert-success").removeClass("d-none");
      $(".alert-success").text("The following users have been put in the Allocated Waiting List");
    }

    this.lockerIdsTarget.dataset.lockerIds = lockerIds
    this.linkTarget.href = `${uri.pop()}?${params.toString()}`

    $(this.selectedLockersTarget).html(`Selected Lockers (${lockerIds.length})`)
  }
}
