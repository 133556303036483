import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["bulkModal", "assignLink", "inputIds", "editField", "nothingSelected"]

  connect() {
    $(this.bulkModalTargets).on("shown.bs.modal", this.confirm.bind(this))
  }

  confirm() {
    const checked = this.element.querySelectorAll("tbody input[type=checkbox]:checked.form-check-input")
    if (checked.length < 1) {
      this.editFieldTargets.forEach(el => el.classList.add("d-none"))
      this.nothingSelectedTargets.forEach(el => el.classList.remove("d-none"))
    } else {
      this.editFieldTargets.forEach(el => el.classList.remove("d-none"))
      this.nothingSelectedTargets.forEach(el => el.classList.add("d-none"))

      let ids = []
      checked.forEach(el => ids.push(el.value))
      this.inputIdsTargets.forEach(el => el.value = ids)
    }
  }
}
