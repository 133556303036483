import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['confirmDeleteDiv', 'deleteLink', 'bulkModal']
  connect() {
    $(this.bulkModalTarget).on('shown.bs.modal', this.confirm.bind(this))

  }

  confirm() {
    this.confirmDeleteDivTarget.innerHTML = ''
    const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`
    const checked = this.element.querySelectorAll('tbody input[type=checkbox]:checked.form-check-input')
    const object = this.confirmDeleteDivTarget.dataset.object
    const objectPlural = this.confirmDeleteDivTarget.dataset.objectPlural
    if (checked.length < 1) {
      this.confirmDeleteDivTarget.innerHTML = `Please select at least 1 ${object}`
      this.deleteLinkTarget.classList.add('d-none')
    } else {
      if(objectPlural == undefined || checked.length === 1) {
        this.confirmDeleteDivTarget.innerHTML = `You are about to delete ${pluralize(checked.length, object)}, continue?`
      } else {
        this.confirmDeleteDivTarget.innerHTML = `You are about to delete ${checked.length} ${objectPlural}, continue?`
      }
      this.deleteLinkTarget.classList.remove('d-none')

      let params = this.deleteLinkTarget.dataset.originPath.split('?')
      let path   = params.shift()

      var tab = ""
      if ($('.nav a.active').attr('id') != undefined) {
        var tab = $('.nav a.active').attr('id')
      }

      params.push('tab='+ tab)
      checked.forEach(el => params.push(`ids[]=${el.value}`))
      this.deleteLinkTarget.setAttribute('href', `${path}?${params.join('&')}`)
    }
  }
}
