import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'all', 'single' ]

  connect() {
    this.toggleAll()
  }

  toggleAll() {
    for (const index in this.singleTargets) {
      this.singleTargets[index].checked = this.allTarget.checked
    }
  }

  toggleSingle() {
    const checked = this.element.querySelectorAll("tbody .form-check input[type=checkbox]:checked")
    this.allTarget.checked = checked.length === this.singleTargets.length
  }
}
