import { Controller } from 'stimulus'

export default class extends Controller {
  bulkSync(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const path = event.target.getAttribute('href')
    const operation = event.target.dataset.operation

    if(!path || !operation) {
      return false
    }

    let data = {}

    data['locker_ids'] = this.getLockerIds()
    data['operation'] = operation

    $.ajax({
      type: 'POST',
      url: path,
      data: data
    })
  }

  getLockerIds() {
    const $scope = this.context.scope.element
    const $selectedLockers = $scope.querySelectorAll('input[type=checkbox]:checked')
    return Array(...$selectedLockers).map(($input) => {
      return $input.value
    })
  }
}
