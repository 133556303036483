import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    $('#status-select').change(function() {
      if ($('#status-select').val() == 'active') {
        $('.locker-section').show()
      } else {
        $('.locker-section').hide()
        $('.locker-field').val('')
        $('.locker-field').trigger('change')
      }
    }).change()
  }
}
