import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modeSelect', 'options', 'systemSelect', 'warnings', 'warningElement']

  connect() {
    this.systemSelectFound = $('[data-switch-mode-target="systemSelect"]').length > 0
    this.isBulk = true
    if(this.systemSelectFound) {
      if(this.optionsTarget.dataset.isBulk === 'true') {
        let systemInitial = null
        let modeInitial = null
        let systemChanged = false
        let modeChanged = false
        $('.locker-bulk-id').toArray().forEach(el => {
          if (systemInitial === null) {
            systemInitial = el.dataset.system
            this.initialSystem = systemInitial
          } else {
            systemChanged = systemInitial != el.dataset.system
          }

          if (modeInitial === null) {
            modeInitial = el.dataset.mode
            this.initialMode = modeInitial
          } else {
            modeChanged = modeInitial != el.dataset.mode
          }
        })

        if (systemChanged) {
          const warning = $(this.warningsTarget).data('system-changed')
          this.printWarning(warning)
        } else if (modeChanged) {
          const warning = $(this.warningsTarget).data('mode-changed')
          this.printWarning(warning)
        }
      } else {
        this.initialSystem = this.systemSelectTarget.dataset.initialValue || this.systemSelectTarget.dataset.value
        this.initialMode = this.modeSelectTarget.dataset.initialValue
        this.isBulk = false
        this.isNewForm = $(this.warningsTarget).parents('form').hasClass('new_locker')
        if (this.initialMode != undefined && this.initialMode != this.modeSelectTarget.value) {
          const warning = $(this.warningsTarget).data('system-changed')
          this.printWarning(warning)
        } else if (this.initialSystem != undefined && this.initialSystem != (this.systemSelectTarget.value || this.systemSelectTarget.dataset.value)) {
          const warning = $(this.warningsTarget).data('mode-changed')
          this.printWarning(warning)
        }
      }
    }
    $('.select2').each((idx, el) => {
      $(el).on('select2:select', (e) => {
        let event = new Event('change', { bubbles: true })
        el.dispatchEvent(event);
      });
    })
  }

  onSystemChanged() {
    let systemType = $(this.systemSelectTarget).find('option:selected').data('system-type')
    $(this.modeSelectTarget).html('')
    if (systemType != undefined) {
      systemType = systemType.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase()
      $(this.systemSelectTarget).removeClass("is-invalid")
      $(this.systemSelectTarget).addClass("is-valid")
      const modes = $(this.optionsTarget).data(systemType)
      modes.forEach(val => {
        let opt = $('<option />')
        opt.val(val[1])
        opt.html(val[0])
        $(this.modeSelectTarget).append(opt)
        if (val[2] == "selected") {
          opt.attr("selected","selected");
        }
      })
    } else {
      $(this.systemSelectTarget).addClass("is-invalid")
      $(this.systemSelectTarget).removeClass("is-valid")
    }

    if (this.initialSystem != undefined && this.initialSystem != this.systemSelectTarget.value) {
      const warning = $(this.warningsTarget).data('system-changed')
      this.printWarning(warning)
    } else {
      this.onModeChanged()
    }
  }

  onModeChanged() {
    if (this.initialSystem != '' && this.initialSystem === (this.systemSelectTarget.value || this.systemSelectTarget.dataset.value)) {
      if (this.initialMode != undefined && this.modeSelectTarget.value != this.initialMode) {
        const warning = $(this.warningsTarget).data('mode-changed')
        this.printWarning(warning)
      } else {
        $(this.warningElementTarget).html('')
      }
    }
  }

  printWarning(warning) {
    if (this.isNewForm === false) {
      let badge = $('<div class="badge badge-danger d-block mb-2" style="white-space: unset" />')
      badge.html(warning)
      $(this.warningElementTarget).html(badge)
    }
  }

  onSave(event) {
    $("#locker_locker_no").removeClass("is-invalid")
    $("#locker_serial_number").removeClass("is-invalid")
    $(this.systemSelectTarget).removeClass("is-invalid")
    if(this.systemSelectFound && this.isNewForm === false && (this.isBulk === false && this.initialSystem != undefined && this.initialMode != undefined || this.isBulk === true)) {
      const systemValue = this.systemSelectTarget.value || this.systemSelectTarget.dataset.value
      if (this.initialSystem != systemValue || this.initialMode != this.modeSelectTarget.value) {
        if (!confirm($(this.warningsTarget).data('confirm'))) {
          event.preventDefault()
          return false
        }
      }
    }
  }
}
