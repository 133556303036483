$(document).on('turbolinks:load', function() {
  $('body').on('click', '#cb_select_all_people', function(event) {
    $('table.people-list tr td input:checkbox').prop('checked', $(this).is(':checked'));
  });

  $('table.people-list tr td').on('click', 'input:checkbox', function(event) {
    if ($(this).not(':checked')) {
      $('#cb_select_all_people').prop('checked', false);
    }
  });

  $('body').on('change', 'select.set-location', function(event) {
    $('table.locker-allocations-list tr td select.locker-location').val($(this).val());
  });

  $('body').on('change', 'select.locker-location', function(event) {
    if ($(this).val() != $('select.set-location').val()) {
      $('select.set-location').val('');
    }
  });

  $('body').on('change', 'select.set-style', function(event) {
    $('table.locker-allocations-list tr td select.locker-style').val($(this).val());
  });

  $('body').on('change', '.set-informed-by-email', function(event) {
    $('table.locker-allocations-list tr td input.informed-by-email').val($(this).is(':checked'));
  });

  $('body').on('change', '.set-informed-by-phone', function(event) {
    $('table.locker-allocations-list tr td input.informed-by-phone').val($(this).is(':checked'));
  });

  $('body').on('change', 'select.locker-style', function(event) {
    if ($(this).val() != $('select.set-style').val()) {
      $('select.set-style').val('');
    }
  });
  
  $('.bulk-allocation-cancellation').click(function(event) {
    event.preventDefault();
    checked = $('tbody input[type=checkbox]:checked')

    var ids = [];
    checked.each(function(){
      ids.push(this.value)
    });
    
    if (ids.length > 0) {
      if(confirm('Are you sure want to cancel selected locker allocations?')) {
        $.ajax({
          url: '/allocated/locker_allocations/bulk_cancel',
          type: "DELETE",
          data: { ids: ids }
        })
      }
    }
    else {
      alert('Please select atleast one locker allocations');
    }
  });


  $('.delete-selected-people').click(function(event) {
    event.preventDefault();
    var ids = getSelectedPeople();

    if (ids.length > 0) {
      var url = $(this).attr('href');

      if(confirm('Are you sure want to delete these people?')) {
        $.post(url, { ids: ids }, function() {
          $.each(ids, function(index, value) { $("table.people-list tr#person_" + value).remove(); });
        });
      }
    }
    else {
      alert('Please select some people');
    }
  });

  $('body').on('click', '.upload-people', function(event) {
    event.preventDefault();
    $('.upload-people-form').show();
    $('.upload-people-error').hide();
  });

  $('body').on('click', '.close-people-allocations-progress', function(event) {
    location.reload();
  });

  $('#sync_people').on("hidden.bs.modal", function(event) {
    $("#sync_people").find(".sync-warning, .btn-sync-people").show()
    $("#sync_people .sync-feedback").hide()
  })

  // $('body').on 'submit', '.upload-people-form', (e)->
  //   e.preventDefault()

  //   url = $(this).attr('action')
  //   data = {}
  //   $.each $(this).serializeArray(), (i, field)->
  //     data[field.name] = field.value || ""

  //   if document.getElementById('csv_import_file').files.length == 0
  //     alert('Please select file.')
  //   else
  //     $('#csv_import_file').simpleUpload url,
  //       data: data
  //       start: (file)->
  //         $.fancybox($('#people_import_progress'), { width: 600 })
  //       progress: (progress)->
  //         # update uploading progress
  //         $('#people_import_progress #step1 .progress-bar').text("#{progress}%").css('width', "#{progress}%").data('aria-valuenow', progress)
  //       success: (data)->
  //         if data.error
  //           $('.upload-people-error').show().html(data.error)
  //           $.fancybox.close()
  //         else
  //           $('.upload-people-error').hide()
  //           $('#people_import_progress').data('status-url', data.status_url)
  //           $('#people_import_progress #step1, #people_import_progress #step2').toggle()
  //           checkStatusOfPeopleImport()
  //       error: (error)->
  //         $('.upload-people-error').show().html(error.message)
  //         $.fancybox.close()
});

function getSelectedPeople() {
  var selectedPeople = [];
  $.each($('table.people-list tr td input:checkbox:checked'), function() {
    selectedPeople.push($(this).val());
  });
  return selectedPeople;
}

window.checkAllocationProgress = function() {
  if (url = $('#people_allocations_progress').data('status-url')) {
    $.get(url, function(data) {
      $('.allocated_count').text(data.allocated);
      $('.progress-bar').text(data.progress + "%").css('width', data.progress + "%").data('aria-valuenow', data.progress);

      var inProgressCount = data.in_progress;
      if (inProgressCount > 0) {
        // check progress after 1 sec
        setTimeout(checkAllocationProgress, 1000);
      } else {
        $('#people_allocations_progress #step2 h5').html('');
        $('#people_allocations_progress #step2 .progress').hide();
        $('#modal h4.text-progress').html('Allocation Process Done')
        var result = data.allocated
        result += data.allocated > 1 ? ' people' : ' person';
        result += ' were allocated';
        $('#people_allocations_progress #step2 h5.allocated').html(result);

        if (data.no_lockers > 0) {
          result = data.no_lockers;
          result += data.no_lockers > 1 ? ' people' : ' person';
          result += ' were added to the allocation waiting list';
          $('#people_allocations_progress #step2 h5.waiting-list').html(result);

        }
        $('#modal').on('hidden.bs.modal', function() {
          window.location.reload()
        })
      }
    });
  }
}

window.checkStatusOfPeopleImport = function() {
  // if url = $('#people_import_progress').data('status-url')
  //   $.get url, (data) ->
  //     if data.status == 0
  //       progress = parseInt((data.imported * 100) / data.total)
  //       $('#people_import_progress #step2 .progress-bar').text("#{progress}%").css('width', "#{progress}%").data('aria-valuenow', progress)
  //       # check status after 1 sec
  //       setTimeout(checkStatusOfPeopleImport, 1000)
  //     else
  //       location.reload()
}
