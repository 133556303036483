import { Controller } from 'stimulus'

export default class extends Controller {
  collectLogs(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const path = event.target.getAttribute('href')

    if(!path) {
      return false
    }

    let ids = this.getLockerIds()

    if (ids.length > 0) {
      $.post(path, { ids: ids })
    }
    else {
      alert('Please select some lockers')
    }
  }

  getLockerIds() {
    const $scope = this.context.scope.element
    const $selectedLockers = $scope.querySelectorAll('.lockers-list input[type=checkbox]:checked')
    return Array(...$selectedLockers).map(($input) => {
      return $input.value
    })
  }
}
