$(document).on('turbolinks:load', function() {

  $('body').on('click', '[data-action="uncheck-to-destroy"]', function(e) {
    target = $(this).data('target')
    $(`#destroy-${target}`).click()
  })


  $('body').on('click', '#cb_select_all_lockers, .check-all-asset-lockers', function() {
    $('table.lockers-list tr td input:checkbox').prop('checked', $(this).is(':checked'));
  });

  $('table.lockers-list tr td').on('click', 'input:checkbox', function() {
    if($(this).not(':checked')){
      $('#cb_select_all_lockers').prop('checked', false);
    }
  });

  $('.delete-selected-lockers').click(function(event) {
    event.preventDefault();

    var selectedLockers = $('table.lockers-list tbody input:checkbox:checked');
    if (selectedLockers.length > 0) {
      if(confirm('Are you sure want to delete these lockers?')) {
        $.post($(this).attr('href'), selectedLockers.serialize(), function() {
          $.each(selectedLockers, function(index, elm) { $("table.lockers-list tr#locker_" + $(elm).val()).remove(); });
        });
      }
    }
    else {
      alert('Please select some lockers');
    }
  });

  $('.print-selected-lockers').click(function(event) {
    event.preventDefault();

    var ids = $('table.lockers-list tbody input:checkbox:checked').serialize();
    if (ids.length > 0) {
      $.post($(this).attr('href'), ids, function(data) {
        $('#modal').find(".modal-content .modal-ajax-body").html(data);
        $('#modal').find('.modal-title').text('Select columns to print');
        $('#modal').modal('show');
      });
    }
    else {
      alert('Please select some lockers');
    }
  });

  $('body').on('click', '.change-statuses', function(event) {
    event.preventDefault();

    var ids = $('table.lockers-list tbody input:checkbox:checked').serialize();
    if (ids.length > 0) {
      $.post($(this).attr('href'), ids, function(data) {
        $('#modal').find(".modal-content .modal-ajax-body").html(data);
        $('#modal').find('.modal-title').text('Change Status');
        $('#modal').modal('show');
      });
    }
    else {
      alert('Please select some lockers');
    }
  });

  $('body').on('change', 'select.set-new-status', function(event) {
    var selectedStatus = $(this).find('option:selected');
    $('.new-status').text(selectedStatus.text())
    $('.locker-status').val(selectedStatus.val())
  });

  $('body').on('click', '.upload-lockers', function(event) {
    event.preventDefault();
    hideAllUploadForms();
    $('.lbl-upload-lockers').text('Select file to import lockers:');
    $('#overwrite_all').val(0);
    $('.upload-lockers-form').show();
  });

  $('body').on('click', '.overwrite-lockers', function(event) {
    event.preventDefault();
    hideAllUploadForms();
    $('.lbl-upload-lockers').text('Select file to remove current and import lockers:');
    $('#overwrite_all').val(1);
    $('.upload-lockers-form').show();
  });


  $('body').on('click', '.clear-out-mode-upload', function(event) {
    event.preventDefault();
    hideAllUploadForms();
    $('.clear-out-mode-upload-form').show();
  });

  $('body').on('change', 'select.resend-system, select.resend-location, select.resend-scope', function(event) {
    const systemId = $('select.resend-system').val()
    const locationId = $('select.resend-location').val()
    const scope = $('select.resend-scope').val()
    if (systemId && locationId && scope == 'Selected') {
      $('#location_system_managers').html("<div class='text-center'>Loading...</div>")
      $.getScript($('select.resend-scope').data('url') + '&system_id=' + systemId + '&location_id=' + locationId)
    }
    else {
      $('#location_system_managers').html('')
    }
  });
});

$(document).on('click', '.end_allocation-all', function(e) {
  var checked = document.querySelectorAll('tbody input[type=checkbox]:checked')
  var count = 0;
  let ids = []
  checked.forEach(function(el) {
    text = el.parentElement.parentElement.parentElement.querySelector(".locker-status").innerHTML
    if (text == "Allocated" || text == "Not Live" || text == "No Card") {
      count++;
      ids.push(el.value)
    }
  });
  if (count > 0) {
    if(confirm(count + " locker(s) will be cleared and " + (checked.length - count) +" locker(s) will be ignored. Are you sure you want to do this?")) {
      $.ajax({
        url: '/allocated/lockers/end_allocation',
        type: "POST",
        data: { ids: ids }
      })
    }
  }
  else {
    alert('Please select some lockers that are Allocated, Not Live or No Card');
  }
})

window.getSelectedLockers = function() {
  var selectedLockers = [];
  $.each($('table.lockers-list tr td input:checkbox:checked'), function() {
    selectedLockers.push($(this).val());
  });
  return selectedLockers;
}

window.hideAllUploadForms = function() {
  $('.upload-lockers-form, .clear-out-mode-upload-form').hide();
}
