import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'paginationPer' ]

  connect() {
    this.paginationPerTargets.forEach(el => {
      $(el).on("select2:select", function () {
        let event = new Event("change")
        this.dispatchEvent(event);
      });
    })

  }

  paginationLimitChanged(event) {
    let data = {}

    data[$(event.target).data("per-name")] = event.target.value

    $.ajax({
      type: 'GET',
      url: $(event.target).data("url"),
      dataType:"script",
      data: data
    })
  }
}
