import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["startDate", "endDate", "pastActionDate"]

  rangeFilterChanged(e) {
    if(this.isStartDateEndDatePresent()) {
      this.goToFilter();
    }
  }

  pastActionDateChanged(e) {
    if(this.pastActionDateTarget.value) {
      this.goToFilter()
    }
  }

  isStartDateEndDatePresent() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value

    if(!startDate || !endDate) {
      return false;
    }

    return true;
  }

  goToFilter() {
    const params = {
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      past_action_date: this.pastActionDateTarget.value,
      main_chart_limit: new URLSearchParams(window.location.search).get("analytics_data_form[main_chart_limit]")
    }
    const location_id = new URLSearchParams(window.location.search).get("location_id");
    window.location.search = $.param({ analytics_data_form: params, location_id: location_id });
  }
}
