$(document).on('turbolinks:load', function() {
  $('body').on('click', '#check-all', function() {
    $('.notifiations-list tr td input:checkbox').prop('checked', $(this).is(':checked'));
  });

  $('.bulk-read-all-notification').click(function(event) {
    event.preventDefault();

    if(confirm('Are you sure want to read all the notifications?')) {
      $.ajax({
        url: $(this).attr('href'),
        type: "DELETE"
      });
    }
  });

  $('.bulk-read-notification').click(function(event) {
    event.preventDefault();
    var ids = getSelectedNotifications();

    if (ids.length > 0) {
      if(confirm('Are you sure want to mark this notifications as read?')) {
        $.ajax({
          url: $(this).attr('href'),
          type: "POST",
          data: { ids: ids }
        });
      }
    }
    else {
      alert('Please select atleast one notification');
    }
  });
});

function getSelectedNotifications() {
  var ids = [];
  $.each($('tbody input[type=checkbox]:checked'), function() {
    ids.push($(this).val());
  });
  return ids;
}
