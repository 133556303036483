// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("select2")
require("../source")
require("jquery")
require("@nathanvda/cocoon")

var moment = require("moment")

global.moment = moment
window.moment = moment

global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "chartkick/chart.js"
import "./custom";
import "./lockers";
import "./people";
import "./asset_system"
import "./sessions";
import "./shared/pusher"
import "./modal"
import "./notification"
import "./users"
import "./search"
import "../custom/daterangepicker.min"

require("trix")
require("@rails/actiontext")

global.toastr = require('toastr')

$(document).on('turbolinks:load', function() {
  toastr.options = {
    "closeButton": false,
    "debug": false,
    "positionClass": "toast-bottom-center",
    "onclick": null,
    "showDuration": "100",
    "hideDuration": "300",
    "tapToDismiss": false,
    "timeOut": "0",
    "extendedTimeOut": "0",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn"
  }
  $('[data-toggle="tooltip"]').tooltip()
});
