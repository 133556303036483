import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ['inputIds', 'editField', 'nothingSelected', 'modalDialog']

  connect() {
    $(this.modalDialogTarget).on('shown.bs.modal', this.confirm.bind(this))
  }

  confirm() {
    const checked = this.element.querySelectorAll('tbody input[type=checkbox]:checked')
    if (checked.length < 1) {
      this.editFieldTargets.forEach(el => el.classList.add('d-none'))
      this.nothingSelectedTarget.classList.remove('d-none')
    } else {
      this.editFieldTargets.forEach(el => el.classList.remove('d-none'))
      this.nothingSelectedTarget.classList.add('d-none')

      let ids = []
      checked.forEach(el => ids.push(el.value))
      this.inputIdsTarget.value = ids
      $($('.modal .select2#locker_location_id').first()).select2()
    }
  }
}
