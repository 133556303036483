document.addEventListener('turbolinks:before-cache', function() {
  $(document).off('click', 'a.modal-ajax')
  $(document).off("click", "a.tab-ajax, a.load-more-audits")
  $(document).off("click", "a.btn-note, a.load-more-notes")
  $('.select2-hidden-accessible').select2('destroy');
  $('#modal').modal('hide');
});

$(document).on('turbolinks:load', function() {
  $(document).find('select').not('.select2-hidden-accessible, .select2-disabled').select2();
  if ($(document).find('select.accounts-select').length != 0) {
    setTimeout(function() {
      // Account select
      $(document).find('select.accounts-select').multiselect({
        nonSelectedText: 'All Accounts',
        buttonWidth: '200px'
      });

      $(document).find('select.accounts-select').removeClass('d-none');
      $(document).find('select.locations-select').removeClass('d-none');
    }, 200)
  }

  if ($(document).find('select.locations-select').length != 0) {
    setTimeout(function() {
      // Location select
      $(document).find('select.locations-select').multiselect({
        nonSelectedText: 'All Locations',
        buttonWidth: '200px'
      });
    }, 200)
  }

  $(document).on('click', 'a.modal-ajax', function(event) {
    event.preventDefault();
    var url = $(this).data('url');
    var header = $(this).data('header');
    if (typeof(header) == 'undefined' || header == '') {
      header = $(this).text();
      if (header == '') {
        header = 'Edit';
      }
    }

    var modalSize = $(this).data('modal-size') || 'modal-lg';
    $("#modal .modal-dialog").removeClass("modal-xl modal-lg modal-md modal-sm");
    $('#modal .modal-dialog').addClass(modalSize);

    $('#modal').find(".modal-content .modal-ajax-body").load(url, function() {
      $('.select2').each((idx, el) => {
        var options = {}
        if (el.dataset.maximumLength !== undefined) options['maximumSelectionLength'] = el.dataset.maximumLength
        $(el).select2(options);
      })
      $('#modal').find('.modal-title').text(header);
      $('#modal').modal('show');
    });
  });

  $(document).on("change.select2", ".account-select", function(e) {
    var url = $(this).data("url");
    var account_selected = $(this).val();

    $.ajax({
      url,
      type: "GET",
      data: { ids: account_selected }
    });
  });

  $(document).on("click", ".btn-locker-history", function(event) {
    $("#modal").css("display", "none");

    event.preventDefault();
    var url = $(this).data("url");
    var header = $(this).data("header");

    $("#modal .modal-dialog").removeClass("modal-xl");
    $("#modal .modal-dialog").addClass("modal-lg");

    $("#modal").find(".modal-content .modal-ajax-body").load(url, function() {
      $("#modal").find(".modal-title").text(header);
      $("#modal").css("display", "block");
    });
  });

  $(document).on("click", "a.tab-ajax", function(e) {
    e.preventDefault();
    var loaded = $(this).data("loaded")
    // set this tab as loaded, make sure revisiting this tab won't reload the data
    $(this).data("loaded", true)
    var url = $(this).data("url")
    var target = $(this).data("container")

    if (loaded != true) {
      $(target).find(".loading-row").removeClass("d-none")

      fetch(url).then(function(response){
        return response.text()
      }).then(function(html) {
        $(target).append(html)

        // move loading rows to very bottom and hide it
        $loadingRows = $(target).find(".loading-row")
        $loadingRows.addClass("d-none")
        $(target).find(".loading-row").remove()
        $(target).append($loadingRows)
      }).catch(function(err) {
        alert("hiccup! something went wrong")
      })
    }
  })

  $(document).off("click", "a.load-more-audits, a.load-more-notes")
  $(document).on("click", "a.load-more-audits, a.load-more-notes", function(e) {
    e.preventDefault();
    // set this tab as loaded, make sure revisiting this tab won't reload the data
    var url = $(this).data("url")
    var target = $(this).closest("tbody");

    // Comments load more container
    if (target == undefined || target.length == 0) {
      target = $(this).data("container");
    }

    if (target != undefined) {
      // Hide old load-more link and show loading animation
      $(target).find("tr.load-more").remove()
      $(target).find(".loading-row").remove()

      fetch(url).then(function(response){
        return response.text()
      }).then(function(html) {
        $(target).append(html)
      }).catch(function(err) {
        alert("hiccup! something went wrong")
      })
    }
  })

  $('.respond-btn').on('click', function(event) {
    toastr.info('Responding....', '', { iconClass: "toast-custom" } )
  });

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active-btn");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } 
      else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }

  $(document).ready(function(){
    $("#access-denied-modal").modal("show");
  });

  $(document).on("keyup", "textarea.note-input", function() {
    var empty = false;
    $(".note-input").each(function() {
      if ($(this).val().length == 0) {
        empty = true;
      }
    });

    if (empty) {
        $("a.btn-note").addClass("disabled");
    } else {
        $("a.btn-note").removeClass("disabled");
    }
  });

  $(document).on("click", "a.btn-note", function(e){
    e.preventDefault();
    let postNote = $(".note-input").val();
    let url = $(this).data("url");
    let token = $(this).data("token");
    let data;

    if ($(".view-mode-option input[type='checkbox']").length > 0){
      data = "&comment[body]="+encodeURIComponent(postNote)+"&authenticity_token="+encodeURIComponent(token);
      for (i = 0; i < $(".view-mode-option input[type='checkbox']").length; i++) {
        if ($(".view-mode-option input[type='checkbox']")[i].checked){
          data = data + "&comment[status]="+$(".view-mode-option input[type='checkbox']")[i].id
        }
      }
      $(".view-mode-option input[type='checkbox']").prop("checked", false);
    } else {
      data = "&note="+encodeURIComponent(postNote)+"&authenticity_token="+encodeURIComponent(token);
    }

    if (postNote == "") {
      $("a.btn-note").addClass("disabled");
    } else {
      fetch(url+data, { method: "POST" }
      ).then(function(response){
        return response.text()
      }).then(function(html) {
        $(".timeline").prepend(html)
      }).catch(function(err) {
        alert("something went wrong")
      })

      $(".note-input").val("");
      $("a.btn-note").addClass("disabled");
    }
  })

  $(".cancel-order").bind('ajax:beforeSend', function() {
    var order_id = $(this).data("order-id");
    $(this).addClass("d-none");
    $(`.cancel-inprogress-${order_id}`).append(`<div class="spinner-border" role="status" style="width: 1.2rem; height: 1.2rem">
                                                  <span class="sr-only">Loading...</span>
                                                </div>`);
  });

  $(document).on("click", ".view-mode-option input[type='checkbox']", function() {
    $("input[type='checkbox']").not(this).prop("checked", false);
  });

  $(document).on("keyup change", "#locker_locker_no, #locker_serial_number, #locker_location_id", function() {
    if ($(this).val() == "") {
      $(this).addClass("is-invalid");
    } else {
      $(this).removeClass("is-invalid");
    }
  });
});
