import Chartist from 'chartist';
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'assetIn', 'assetOut', 'assetBroken', 'assetMaintenance', 'chart' ]

  connect() {
    new Chartist.Pie(this.chartTarget, {
      series: this.getSeries()
    }, {
      donut: true,
      donutWidth: 20,
      donutSolid: true,
      startAngle: 0,
      showLabel: false
    });
  }

  getSeries() {
    return [{
        value: this.assetInTarget.innerHTML,
        name: 'Asset In',
        className: 'asset-in-color',
      }, {
        value: this.assetOutTarget.innerHTML,
        name: 'Asset Out',
        className: 'asset-out-color',
      }, {
        value: this.assetBrokenTarget.innerHTML,
        name: 'Asset Broken',
        className: 'asset-broken-color',
      }, {
        value: this.assetMaintenanceTarget.innerHTML,
        name: 'Asset Maintenance',
        className: 'asset-maintenance-color',
      }].filter(data => data.value > 0);
  }
}
