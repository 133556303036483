import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['versionSelect', 'options', 'lockSelect', 'warnings']

  connect(){
    this.lockSelectFound = $('[data-switch-version-target="lockSelect"]').length > 0
    console.log(this.lockSelectFound)
    this.initialLock = this.lockSelectTarget.dataset.initialValue || this.lockSelectTarget.dataset.value
    this.initialVersion = this.versionSelectTarget.dataset.initialValue
  }

  onLockChanged() {
    let lockType = this.lockSelectTarget.value
    $(this.versionSelectTarget).html("")
    if (lockType != undefined) {
      lockType = lockType.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
      $(this.lockSelectTarget).removeClass("is-invalid")
      $(this.lockSelectTarget).addClass("is-valid")
      const versions = $(this.optionsTarget).data(lockType)
      versions.forEach((val) => {
        let opt = $("<option />")
        opt.val(val[1])
        opt.html(val[0])
        $(this.versionSelectTarget).append(opt)
      })
    } else {
      $(this.lockSelectTarget).addClass("is-invalid")
      $(this.lockSelectTarget).removeClass("is-valid")
    }
    this.onVersionChanged()
  }

  onVersionChanged() {
     if (this.initialLock != '' && this.initialLock === (this.lockSelectTarget.value || this.lockSelectTarget.dataset.value)) {
      if (this.initialVersion != undefined && this.versionSelectTarget.value != this.initialVersion) {
        const warning = $(this.warningsTarget).data('version-changed')
        this.printWarning(warning)
      } else {
        $(this.warningElementTarget).html('')
      }
    }
  }
  printWarning(warning) {
    if (this.isNewForm === false) {
      let badge = $('<div class="badge badge-danger d-block mb-2" style="white-space: unset" />')
      badge.html(warning)
      $(this.warningElementTarget).html(badge)
    }
  }
}
