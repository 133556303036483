import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'dataLink', 'prev']
  connect() {
    // get lockers elements and current locker id
    let lockers = [...document.querySelectorAll('tr.locker')]
    const currentId = this.element.dataset.currentId

    // get current dom_id(locker) index
    const lockerIndex = lockers.findIndex(el => el.getAttribute('id') === `locker_${currentId}`)

    // get next and prev locker element
    const nextLocker = lockers[lockerIndex + 1] || lockers[0]
    const prevLocker = lockers[lockerIndex - 1] || lockers[lockers.length -1]

    // get next and prev locker id
    const nextLockerId = nextLocker.getAttribute('id').replace('locker_', '')
    const prevLockerId = prevLocker.getAttribute('id').replace('locker_', '')

    if (nextLockerId != currentId) {
      // add next_locker_id to url params
      if (this.hasLinkTarget) {
        this.linkTargets.forEach(link => this.applyNextLockerId(link, nextLockerId))
      }

      // add next_locker_id to url params on link with data-url instead of href
      if (this.hasDataLinkTarget) {
        this.dataLinkTargets.forEach(link => this.applyNextLockerId(link, nextLockerId, 'dataUrl'))
      }
    }

    if (prevLockerId != currentId) {
      // add next_locker_id to url params
      if (this.hasPrevTarget) {
        this.prevTargets.forEach(link => this.applyNextLockerId(link, prevLockerId))
      }
    }
  }

  applyNextLockerId(link, nextLockerId, linkType='href') {
    if(linkType === 'href') {
      let url = link.getAttribute('href').split('?')
      let searchParams = new URLSearchParams(url.pop())

      searchParams.append('next_locker_id', nextLockerId)
      link.setAttribute('href', `${url[0]}?${decodeURIComponent(searchParams.toString())}`)
    } else if(linkType === 'dataUrl') {
      let url = link.dataset.url.split('?')
      let searchParams = new URLSearchParams(url.pop())

      searchParams.append('next_locker_id', nextLockerId)
      link.dataset.url = `${url[0]}?${decodeURIComponent(searchParams.toString())}`
    }
  }
}
