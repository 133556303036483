import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  delete(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const path = event.target.getAttribute('href')

    if(!path) {
      return false
    }

    let data = {}

    data['ids'] = this.getAssetIds()

    if(data['ids'] === undefined || data['ids'].length <= 0) {
      alert('Please select an asset')
      return
    }

    if(confirm(`Are you going to delete ${data['ids'].length} assets?`)) {
      $.ajax({type: 'POST', url: path, data: data})
    }
  }

  getAssetIds() {
    const $scope = this.context.scope.element
    const $selectedAssets = $scope.querySelectorAll('input[type=checkbox]:checked')
    return Array(...$selectedAssets).map(($input) => {
      return $input.value
    }).filter((a) => a)
  }
}
