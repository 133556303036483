import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["confirmResetDiv", "resetLink", "bulkResetModal", "statusField", "warningElement", "warnings",
    "inputIds", "link"]

  connect() {
    $(this.bulkResetModalTargets).on('shown.bs.modal', this.confirm.bind(this))
    $('.select2').each((idx, el) => {
      $(el).on('select2:select', (e) => {
        let event = new Event('change', { bubbles: true })
        el.dispatchEvent(event);
      });
    });
  }

  confirm() {
    this.confirmResetDivTarget.innerHTML = ''
    const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`
    const checked = this.element.querySelectorAll('tbody input[type=checkbox]:checked.form-check-input')
    const object = this.confirmResetDivTarget.dataset.object
    const objectPlural = this.confirmResetDivTarget.dataset.objectPlural
    if (checked.length < 1) {
      this.confirmResetDivTarget.innerHTML = `Please select at least 1 ${object}`
      this.statusFieldTargets.forEach(el => el.classList.add('d-none'))
      this.resetLinkTarget.classList.add('d-none')
      }
    else {
      if(objectPlural == undefined || checked.length === 1) {
        this.confirmResetDivTarget.innerHTML = `You are going to reset ${pluralize(checked.length, object)}, continue?`
      } else {
        this.confirmResetDivTarget.innerHTML = `You are going to reset ${checked.length} ${objectPlural}, continue?`
      }
      this.statusFieldTargets.forEach(el => el.classList.remove('d-none'))
      this.resetLinkTarget.classList.remove('d-none')

      let params = this.resetLinkTarget.dataset.originPath.split('?')
      let path   = params.shift()

      var tab = ""
      if ($('.nav a.active').attr('href') != undefined) {
        var tab = $('.nav a.active').attr('href').split('#')[1]
      }

      params.push("tab=" + tab)
      checked.forEach((el) => params.push(`ids[]=${el.value}`))
      this.resetLinkTarget.setAttribute("href", `${path}?${params.join("&")}`)

      let ids = []
      checked.forEach(el => ids.push(el.value))
      this.inputIdsTargets.forEach(el => el.value = ids)
      this.onLockerReset()
    }
  }

  openModal() {
    const checked = $(this.element).find('tbody input[type=checkbox]:checked')
    if (checked.length === 0) {
      alert("Please select locker(s)")
    } else {
      const ids = checked.map((idx, el) => el.value).toArray()
      let params = this.linkTarget.dataset.originUrl
      params = params.split("?")

      const originUrl = params.shift()
      ids.forEach((id) => {
        params.push(`locker_ids[]=${id}`)
      })
      const url = `${originUrl}?${params.join("&")}`

      $("#modal")
        .find(".modal-content .modal-ajax-body")
        .load(url, function () {
          $("#modal").find(".modal-title").text("Reset Lockers")
          $("#modal").find(".modal-dialog").removeClass("modal-lg")
          $("#modal").find(".modal-dialog").addClass("modal-md")
          $("#modal").modal("show")
        })
    }
  }

  onLockerReset() {
    const selectedValue = this.element.querySelector("#select2-locker_resetting_to-container")?.title
    const bulkselectedValue = $("#select2-bulk_reset_allocation_resetting_to-container")[0]?.title
    if (selectedValue == "Empty" || bulkselectedValue == "Empty") {
      const warning = $(this.warningsTarget).data("reset-option-selected")
      this.printWarning(warning)
    } else {
      $(this.warningElementTarget).html("")
    }
  }

  printWarning(warning) {
    let badge = $('<div class="badge badge-danger text-wrap" />')
    badge.html(warning)
    $(this.warningElementTarget).html(badge)
  }
}
