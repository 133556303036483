import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'orderTypeNext',
    'orderWorkflow',
    'orderReference',
    'referenceError',
    // steps
    'selectWorkflow',
    'selectAsset',
    'selectExchangeAsset',
    'newAsset',
    'selectLocker',
    'selectPerson',
    'confirmation',
  ]

  connect() {
    this.defaultWorkflows  = [this.selectWorkflowTarget, this.selectAssetTarget, this.selectLockerTarget, this.selectPersonTarget, this.confirmationTarget]
    this.newAssetWorkflows = [this.selectWorkflowTarget, this.newAssetTarget, this.selectLockerTarget, this.selectPersonTarget, this.confirmationTarget]
    // select free / predeployed asset first then select asset in hold of person
    // in other word : select collect workflow first, then select return workflow
    this.exchangeWorkflows = [this.selectWorkflowTarget, this.selectAssetTarget, this.selectLockerTarget, this.selectExchangeAssetTarget, this.confirmationTarget]

    this.workflows = this.defaultWorkflows
    this.isNew = this.element.dataset['isNew']
    this._setDefaultTitle()
    if (this.isNew == 'false') {
      this.selectWorkflowTarget.classList.add('d-none')
      this.confirmationTarget.classList.remove('d-none')
      this._setTitle()
      this._loadData()
    }

    $('.select2').each((idx, el) => {
      $(el).on('select2:select', (e) => {
        let event = new Event('change', { bubbles: true })
        el.dispatchEvent(event);
      });
    })
  }

  onChangeWorkflowReference() {
    const referenceValid = this.validateReference();
    const workflowValid = this.validateSelectWorkflow();
    if (referenceValid && workflowValid) {
      this.orderTypeNextTarget.classList.remove("d-none");
    } else {
      this.orderTypeNextTarget.classList.add("d-none");
    }
  }

  validateReference() {
    if (this.orderReferenceTarget.value.length <= 12) {
      this.referenceErrorTarget.classList.add("d-none");
      return true;
    }
    this.referenceErrorTarget.classList.remove("d-none");
    return false;
  }

  validateSelectWorkflow() {
    if (this.orderWorkflowTarget.value !== "") {
      return true;
    }
    return false;
  }

  selectAssetForCollect(event=null) {
    const value = $(event.target).data('id')
    const input = $(event.target).data('input')
    const lockerId = $(event.target).data('locker-id')
    const lockerInput = $(event.target).data('locker-input')
    const orderType = $('#collect_order_order_type').val()
    $(input).val(value)

    const selectedWorkflows = orderType === 'exchange' ? this.exchangeWorkflows : this.defaultWorkflows

    if (lockerId != undefined) {
      this.workflows = selectedWorkflows.filter(el => el != this.selectLockerTarget)
      $(lockerInput).val(lockerId)
    } else {
      this.workflows = selectedWorkflows
    }

    this.next()
  }

  selectAssetForReturn(event=null) {
    const value = $(event.target).data('id')
    const input = $(event.target).data('input')
    const personId = $(event.target).data('person-id')
    const personInput = $(event.target).data('person-input')
    const orderType = $('#collect_order_order_type').val()
    $(input).val(value)
    $(personInput).val(personId)

    const selectedWorkflows = orderType === 'exchange' ? this.exchangeWorkflows : this.defaultWorkflows

    this.workflows = selectedWorkflows.filter(el => el != this.selectPersonTarget)
    this.next()
  }

  selectRow(event=null) {
    const value = $(event.target).data('id')
    const input = $(event.target).data('input')
    $(input).val(value)

    if (this.isNew == 'false') {
      this.showConfirm()
    } else {
      this.next()
    }
  }

  newAsset() {
    this.workflows = this.newAssetWorkflows
    this.newAssetTarget.classList.remove('d-none')
    this.selectAssetTarget.classList.add('d-none')
    this._setTitle()
    this._loadData()
  }

  onNewAssetValidated(event) {
    if(typeof(event.detail[0]) === 'object') {
      const response = event.detail[0]
      $('#order_asset_uid').val(response.uid)
      $('#order_asset_serial_number').val(response.serial_number)
      $('#order_asset_type_id').val(response.asset_type_id)
      this.next()
    }
  }

  next(event=null) {
    if (this.isNew == 'false') {
      this.showConfirm()
    } else {
      this._findCurrentStep()
      const nextStep = this.currentIndexStep + 1
      if(this.workflows[nextStep] !== undefined) {
        this.workflows[nextStep].classList.remove('d-none')
        this.currentStep.classList.add('d-none')
      }
      this._setTitle()
      this._loadData()
    }
  }

  back(event=null) {
    this._findCurrentStep()
    if (this.isNew == 'true') {
      const prevStep = this.currentIndexStep - 1
      if(this.workflows[prevStep] !== undefined) {
        this.workflows[prevStep].classList.remove('d-none')
        this.currentStep.classList.add('d-none')
        console.log()
        if (this.workflows[prevStep] === this.selectWorkflowTarget) this.workflows = this.defaultWorkflows
      }
      this._setTitle()
      this._loadData()
    } else {
      this.showConfirm()
    }
  }

  // Toggle hide/show on field collection ( make available / require collection ) select
  specifyAssetCondition(e) {
    if (e.target.value === 'faulty') {
      this.element.querySelector('.form-group.collect_order_collection').classList.add('d-none')
      this.element.querySelector('.form-group.collect_order_collect_asset_fault_reason').classList.remove('d-none')
    } else {
      this.element.querySelector('.form-group.collect_order_collection').classList.remove('d-none')
      this.element.querySelector('.form-group.collect_order_collect_asset_fault_reason').classList.add('d-none')
    }
  }

  repickAsset() {
    this._findCurrentStep()
    this.currentStep.classList.add('d-none')
    this.selectAssetTarget.classList.remove('d-none')
    this._setTitle()
    this._loadData()
  }

  repickPerson() {
    this._findCurrentStep()
    this.currentStep.classList.add('d-none')
    this.selectPersonTarget.classList.remove('d-none')
    this._setTitle()
    this._loadData()
  }

  repickLocker() {
    this._findCurrentStep()
    this.currentStep.classList.add('d-none')
    this.selectLockerTarget.classList.remove('d-none')
    this._setTitle()
    this._loadData()
  }

  showConfirm() {
    this._findCurrentStep()
    this.currentStep.classList.add('d-none')
    this.confirmationTarget.classList.remove('d-none')
    this._setTitle()
    this._loadData()
  }


  //////
  _findCurrentStep() {
    this.currentStep = this.element.querySelector('.order-wizards:not(.d-none)')
    this.currentIndexStep = this.workflows.findIndex(el => el === this.currentStep)
  }

  _setTitle() {
    this._findCurrentStep()
    if (this.isNew === 'true') {
      this.titleElement.querySelector('.order-title').textContent = ` ${this.currentIndexStep + 1}. ${this.currentStep.dataset['title']}`
    } else {
      this.titleElement.querySelector('.order-title').textContent = ` ${this.currentStep.dataset['title']}`
    }
  }

  // add <span class='order-title'> to <h5 class='modal-title'>
  _setDefaultTitle() {
    const getTitle = (el) => {
      while ((el = el.parentElement) && !el.classList.contains('modal-dialog'));
      return el.querySelector('.modal-title')
    }
    this.titleElement = getTitle(this.element)
    let el = document.createElement('span')
    el.classList.add('order-title')
    el.classList.add('font-weight-normal')
    this.titleElement.append(el)
    this._setTitle()
  }

  _loadData() {
    this._findCurrentStep()
    let url = this.currentStep.dataset['url']

    if (url !== undefined) {
      url = url.split('?')
      const urlSearch = new URLSearchParams(url.pop())
      $.each($(this.selectWorkflowTarget).find('input, select'), (index, el) => {
        if(el.value != '') urlSearch.append(el.getAttribute('name'), el.value)
      })

      const $currentStep = $(this.currentStep).find('.wizard-body')

      $currentStep.load(`${url.pop()}?${urlSearch.toString()}`, (response, status, xhr) => {
        $currentStep.find('.select2').each((idx, el) => {
          var options = {}
          if (el.dataset.maximumLength !== undefined) options['maximumSelectionLength'] = el.dataset.maximumLength
          $(el).select2(options).on('select2:select', () => {
            let event = new Event('change', { bubbles: true })
            el.dispatchEvent(event);
          });
        })
      })
    }
  }
}
