import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link']

  openModal() {
    const checked = $(this.element).find('tbody input[type=checkbox]:checked')
    if (checked.length === 0) {
      alert('Please select locker(s)')
    } else {
      const ids = checked.map((idx, el) => el.value).toArray()
      let params = this.linkTarget.dataset.originUrl
      params = params.split('?')

      const originUrl = params.shift()
      ids.forEach(id => {
        params.push(`locker_ids[]=${id}`)
      })
      const url = `${originUrl}?${params.join('&')}`

      $('#modal').find(".modal-content .modal-ajax-body").load(url, function() {
        $('#modal').find('.modal-title').text('Switch Reader Mode');
        $('#modal').modal('show');
      })
    }
  }
}
