$(document).on('turbolinks:load', function() {
  var prevQuery = ''
  $("input.global-search").keyup(function() {
    var query = $(this).val();
    if(query.length > 0) {
      if(prevQuery == query) { return }
      prevQuery = query
      var request = $.ajax({
        url: $("input.global-search").data('url'),
        method: "GET",
        data: { query: query },
        dataType: "json",
        beforeSend: function() {
          $(".global-search-results").show().html("<div class='h6 py-3 text-center'>Search...</div>");
        },
        success: function(response) {
          showSearchResults(response)
        }
      })
    }
    else {
      $(".global-search-results").hide()
    }
  }).blur(function() {
    setTimeout(() => { $(".global-search-results").hide() }, 200)
  }).focus(function() {
    if($(this).val().length > 0) {
      $(".global-search-results").show()
    }
  })
  $(document).on('click', '.global-search-results .d-flex:not(.result-header)', function() {
    window.location = $(this).data('url')
  })
})

window.showSearchResults = function(response) {
  var results = $(".global-search-results")
  results.show().html("")
  if(response.allocated_lockers.data.length == 0 && response.hot_lockers.data.length == 0 &&
    response.asset_lockers.data.length == 0 && response.asset_plus_lockers.data.length == 0 &&
    response.click_and_collect_lockers.data.length == 0 && response.collect_lockers.data.length == 0 &&
    response.people.data.length == 0 && response.teams.data.length == 0 &&
    response.collect_orders.data.length == 0 && response.asset_plus_orders.data.length == 0 &&
    response.collect_assets.data.length == 0 && response.asset_plus_assets.data.length == 0 &&
    response.click_and_collect_orders.data.length == 0 && response.users.data.length == 0 && response.assets.data.length == 0) {
    results.html("<div class='h6 pt-2 text-center'>Not found</div>");
  }
  else {
    addLockersToSearchResults(response.allocated_lockers.data, response.allocated_lockers.meta.total, "Allocated Lockers", "/allocated/lockers")
    addLockersToSearchResults(response.hot_lockers.data, response.hot_lockers.meta.total, "Hot Lockers", "/hot/lockers")
    addLockersToSearchResults(response.asset_lockers.data, response.asset_lockers.meta.total, "Asset Lockers", "/asset/lockers")
    addLockersToSearchResults(response.asset_plus_lockers.data, response.asset_plus_lockers.meta.total, "Asset+ Lockers", "/asset_plus/lockers")
    addLockersToSearchResults(response.click_and_collect_lockers.data, response.click_and_collect_lockers.meta.total, "Click & Collect Lockers", "/click_and_collect/lockers")
    addLockersToSearchResults(response.collect_lockers.data, response.collect_lockers.meta.total, "Techbar Lockers", "/collect/lockers")
    addPeopleToSearchResults(response.people.data, response.people.meta.total)
    addTeamsToSearchResults(response.teams.data, response.teams.meta.total)
    addCollectOrdersToSearchResults(response.collect_orders.data, response.collect_orders.meta.total)
    addCollectAssetsToSearchResults(response.collect_assets.data, response.collect_assets.meta.total)
    addAssetPlusAssetsToSearchResults(response.asset_plus_assets.data, response.asset_plus_assets.meta.total)
    addAssetPlusOrdersToSearchResults(response.asset_plus_orders.data, response.asset_plus_orders.meta.total)
    addClickAndCollectOrdersToSearchResults(response.click_and_collect_orders.data, response.click_and_collect_orders.meta.total)
    addUsersToSearchResults(response.users.data, response.users.meta.total)
    addAssetsToSearchResults(response.assets.data, response.assets.meta.total)
  }
}

window.addSearchResultsHeader = function(title, url, count) {
  var divTitle = $('<div />').addClass('mr-1').html(title)
  var divCount = $('<div />').addClass('total-count').html(count + " Result" + (count != 1 ? 's' : ''))
  var divLink = $('<div />').addClass('view-all ml-auto').append($('<a />').text('View All').attr('href', url))
  return $('<div />').addClass('d-flex flex-row align-self-center align-items-center result-header')
    .append(divTitle).append(divCount).append(divLink)
}

window.addLockersToSearchResults = function(lockers, total, title, url) {
  if(total == 0) { return }
  var results = $(".global-search-results")

  var header = addSearchResultsHeader(title, url + "?search_elasticsearch_locker[query]=" + $("input.global-search").val() + "&limit=" + total, total)
  results.append(header)

  $.each(lockers.slice(0, 3), function(index, locker) {
    var locker_no = $('<div />').addClass('l-no').text(locker.attributes.locker_no)
    locker_no.append($('<span />').addClass('d-block').text(locker.attributes.serial_number))
    var lockerStatus = locker.attributes.status_label
    var status = $('<div />').addClass('l-status')
      .append($('<span />').addClass(lockerStatus.toLowerCase().replace(' ', '-')).text(lockerStatus))
    var person = $('<div />').addClass('l-person')
    if(locker.attributes.person) {
      person.text(locker.attributes.person.name)
      var uid = $('<span />').addClass('d-block')
      if(locker.attributes.person.uid && locker.attributes.person.uid.length > 0) {
        uid.text(locker.attributes.person.uid)
      }
      else {
        uid.text(locker.attributes.person.card_uid)
      }
      person.append(uid)
    }
    var location = $('<div />').text(locker.attributes.location_name)
    location.append($('<span />').addClass('d-block').text(locker.attributes.location_id))
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?id=' + locker.attributes.id})
        .append(locker_no).append(status).append(person).append(location)
    )
  })
}

window.addPeopleToSearchResults = function(people, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/people'

  results.append(addSearchResultsHeader("People", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(people.slice(0, 3), function(index, person) {
    var name = $('<div />').addClass('p-name').html(person.attributes.name)
    var uid = $('<span />').addClass('d-block')
    if (person.attributes.deleted_at) {
      url += "/deleted";
    }
    if(person.attributes.uid && person.attributes.uid.length > 0) {
      uid.html(person.attributes.uid)
    }
    else {
      uid.html(person.attributes.card_uid)
    }
    name.append(uid)
    var email = $('<div />').addClass('p-email')
    if(person.attributes.email && !person.attributes.email.includes('@people.elocker.com')) {
      email.text(person.attributes.email)
    }
    else {
      email.append($('<span />').addClass('text-muted').text('No Email'))
    }
    var phone = $('<div />').addClass('p-phone')
    if(person.attributes.mobile_number && person.attributes.mobile_number.length > 0) {
      phone.text(person.attributes.mobile_number)
    }
    else {
      phone.append($('<span />').addClass('text-muted').text('No Phone'))
    }
    var lockers = $('<div />').addClass('p-lockers')
    if(person.attributes.lockers.length == 1) {
      var locker = person.attributes.lockers[0]
      lockers.text(locker.locker_no)
        .append($('<span />').addClass('d-block').text(locker.serial_number))
    }
    else if(person.attributes.lockers.length > 1) {
      lockers.text(person.attributes.lockers.length + " Lockers")
    }
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?id=' + person.attributes.id})
        .append(name).append(email).append(phone).append(lockers)
    )
  })
}

window.addUsersToSearchResults = function(users, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = "/users"

  results.append(addSearchResultsHeader("Users", url + "?q[keyword_match_any]=" + $("input.global-search").val() + "&limit=" + total, total))

  $.each(users.slice(0, 3), function(index, user) {
    var name = $("<div />").addClass("p-name").html(user.attributes.name)
    var uid = $("<span />").addClass("d-block")
    if (user.attributes.deleted_at) {
      url += "/deleted";
    }
    if(user.attributes.uid && user.attributes.uid.length > 0) {
      uid.html(user.attributes.uid)
    }
    else {
      uid.html(user.attributes.card_uid)
    }
    name.append(uid)
    var email = $("<div />").addClass("p-email")
    if(user.attributes.email && !user.attributes.email.includes("@people.elocker.com")) {
      email.text(user.attributes.email)
    }
    else {
      email.append($("<span />").addClass("text-muted").text("No Email"))
    }
    var phone = $("<div />").addClass("p-phone")
    if(user.attributes.mobile_number && user.attributes.mobile_number.length > 0) {
      phone.text(user.attributes.mobile_number)
    }
    else {
      phone.append($("<span />").addClass("text-muted").text("No Phone"))
    }
    results.append(
      $("<div />")
        .addClass("d-flex flex-row align-self-center align-items-center")
        .attr({"data-url": url + "?q[keyword_match_any]=" + user.attributes.email})
        .append(name).append(email).append(phone)
    )
  })
}

window.addAssetsToSearchResults = function(asset, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/asset/assets'

  results.append(addSearchResultsHeader("Asset System Assets", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(asset.slice(0, 3), function(index, asset) {
    var uid = $('<div />').addClass('t-name').html(asset.attributes.uid)
    uid.append($('<span />').addClass('d-block').html(asset.attributes.serial_number))
    var assetStatus = asset.attributes.status
    var status = $("<div />").addClass("l-status").addClass("mr-2")
      .append($('<span />').addClass(assetStatus.toLowerCase().replace(' ', '-')).text(assetStatus))
    var assetType = asset.attributes.asset_type.name
    var assetTypeName = $('<div />').addClass('ml-1')
      .html(' Asset Type ' + assetType)
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?query=' + asset.attributes.serial_number})
        .append(uid).append(status).append(assetTypeName)
    )
  })
}

window.addTeamsToSearchResults = function(teams, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/teams'

  results.append(addSearchResultsHeader("Teams", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(teams.slice(0, 3), function(index, team) {
    var name = $('<div />').addClass('t-name').html(team.attributes.name)
    name.append($('<span />').addClass('d-block').html(team.attributes.id))
    var membersCount = team.attributes.people ? team.attributes.people.length : 0
    var members = $('<div />').addClass('t-members')
      .html(membersCount + ' Member' + (membersCount != 1 ? 's' : ''))
    var lockersCount = team.attributes.lockers ? team.attributes.lockers.length : 0
    var lockers = $('<div />').addClass('ml-1')
      .html(lockersCount + ' Locker' + (lockersCount != 1 ? 's' : ''))
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?id=' + team.attributes.id})
        .append(name).append(members).append(lockers)
    )
  })
}

window.addCollectOrdersToSearchResults = function(collect_orders, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/collect/orders'

  results.append(addSearchResultsHeader("Collect Orders", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(collect_orders.slice(0, 3), function(index, collect_orders) {
    var reference = $('<div />').addClass('t-name').html(collect_orders.attributes.reference)
    reference.append($('<span />').addClass('d-block').html(collect_orders.attributes.order_id))
    var assets = collect_orders.attributes.collect_asset || collect_orders.attributes.exchange_asset
    var asset = $('<div />').addClass('t-asset')
      .html('Asset Serial Number ' + assets.serial_number )
    var lockerNo = collect_orders.attributes.locker.locker_no
    var locker = $('<div />').addClass('ml-1')
      .html(' Locker No ' + lockerNo)
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?query=' + $("input.global-search").val()})
        .append(reference).append(asset).append(locker)
    )
  })
}

window.addAssetPlusOrdersToSearchResults = function(orders, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/asset_plus/orders'

  results.append(addSearchResultsHeader("Asset Plus Events", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(orders.slice(0, 3), function(index, orders) {
    var id = $('<div />').addClass('t-name').html(orders.attributes.id)
    id.append($('<span />').addClass('d-block').html(orders.attributes.status))
    var orderWorkflow = orders.attributes.workflow
    var workflow = $('<div />').addClass('l-status')
      .append($('<span />').addClass(orderWorkflow.toLowerCase().replace(' ', '-')).text(orderWorkflow))
    var lockerNo = orders.attributes.locker.locker_no
    var locker = $('<div />').addClass('ml-1')
      .html(' Locker No ' + lockerNo)
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?query=' + $("input.global-search").val()})
        .append(id).append(workflow).append(locker)
    )
  })
}

window.addAssetPlusAssetsToSearchResults = function(asset, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/asset_plus/assets'

  results.append(addSearchResultsHeader("Asset Plus Assets", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(asset.slice(0, 3), function(index, asset) {
    var uid = $('<div />').addClass('t-name').html(asset.attributes.uid)
    uid.append($('<span />').addClass('d-block').html(asset.attributes.serial_number))
    var assetStatus = asset.attributes.status
    var status = $("<div />").addClass("l-status").addClass("mr-2")
      .append($('<span />').addClass(assetStatus.toLowerCase().replace(' ', '-')).text(assetStatus))
    var assetType = asset.attributes.asset_type.name
    var assetTypeName = $('<div />').addClass('ml-1')
      .html(' Asset Type ' + assetType)
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?query=' + $("input.global-search").val()})
        .append(uid).append(status).append(assetTypeName)
    )
  })
}

window.addCollectAssetsToSearchResults = function(asset, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = '/collect/assets'

  results.append(addSearchResultsHeader("Collect Assets", url + '?query=' + $("input.global-search").val() + "&limit=" + total, total))

  $.each(asset.slice(0, 3), function(index, asset) {
    var uid = $('<div />').addClass('t-name').html(asset.attributes.uid)
    uid.append($('<span />').addClass('d-block').html(asset.attributes.serial_number))
    var assetStatus = asset.attributes.status
    var status = $('<div />').addClass('l-status')
      .append($('<span />').addClass(assetStatus.toLowerCase().replace(' ', '-')).text(assetStatus))
    var assetType = asset.attributes.asset_type.name
    var assetTypeName = $('<div />').addClass('ml-1')
      .html(' Asset Type ' + assetType)
    results.append(
      $('<div />')
        .addClass('d-flex flex-row align-self-center align-items-center')
        .attr({'data-url': url + '?query=' + asset.attributes.uid})
        .append(uid).append(status).append(assetTypeName)
    )
  })
}

window.addClickAndCollectOrdersToSearchResults = function(orders, total) {
  if(total == 0) { return }
  var results = $(".global-search-results")
  var url = "/click_and_collect/orders/"

  results.append(addSearchResultsHeader("Click And Collect Orders", url + "?query=" + $("input.global-search").val() + "&limit=" + total, total))

  $.each(orders.slice(0, 3), function(index, orders) {
    var order_id = $("<div />").addClass("t-name").html(orders.attributes.uid)

    var name = $('<span />').addClass('t-name')
    if(orders.attributes.customer_name) {
      name.html(orders.attributes.customer_name)
    }
    else {
      name.append($('<span />').addClass('text-muted d-block').html('Empty Name'))
    }

    var email = $("<div />").addClass("ml-1").html(orders.attributes.customer_email)
    email.append($('<span />').addClass('d-block').html(orders.attributes.customer_uid))

    results.append(
      $("<div />")
        .addClass("d-flex flex-row align-self-center align-items-center")
        .attr({"data-url": url + "?query=" + $("input.global-search").val()})
        .append(order_id).append(name).append(email)
    )
  })
}
