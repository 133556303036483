import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let data = JSON.parse(this.element.dataset.chartData)
    let keys = []
    let values = []
    for (let key in data) {
      keys.push(key)
      values.push({
        value: data[key],
        className: `pie-${key.toLowerCase()}-color`
      })
    }

    this.chart = new Chartist.Pie(this.element, {
      labels: keys,
      series: values
    }, {
      donut: true,
      donutWidth: 20,
      donutSolid: true,
      startAngle: 0,
      showLabel: false
    })
  }
}
