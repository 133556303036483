window.$ = $
window.jQuery = $
require("bootstrap")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(document).on('turbolinks:load', function() {
  ///////
  // translation missing searcher
  window.printTranslationMissing = () => {
    $('.translation_missing').each((idx, el) => {
      console.log($(el), $(el).attr('title'))
    })
  }
  printTranslationMissing()

  $('.select2').each((idx, el) => {
    var options = {}
    if (el.dataset.maximumLength !== undefined) options['maximumSelectionLength'] = el.dataset.maximumLength
    $(el).select2(options);
  })

  window.addEventListener('popstate', function (e) {
    var state = e.state;
    if (state !== null) {
      window.location.reload();
    }
  });
})
