import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'ids', 'idsDiv', 'formButton', 'form']

  doExport(e) {
    let ids = []
    const originalUrl = e.target.dataset.originalUrl
    const exportUrl   = e.target.dataset.url

    this.idsDivTarget.innerHTML = ''
    const checked = [...this.element.querySelectorAll('tbody input[type=checkbox]:checked')]
      .filter( (elm) => elm.classList.contains('form-check-input'))

    if (checked.length == 0 && !exportUrl.includes('log')) {
      alert('Please select at least 1 data')
      return false;
    }

    if (checked.length > 0) {
      checked.forEach(i => ids.push(i.value))
    } else {
      this.idsTargets.forEach(i => {
        const pane = i.closest('.tab-pane')
        if (pane == null) {
          ids.push(i.value)
        } else {
          const style = window.getComputedStyle(pane)
          if (style.display != 'none') {
            ids.push(i.value)
          }
        }
      })
    }

    let input
    ids.forEach(i => {
      input = document.createElement('input')
      input.setAttribute('name', `q[id_in][]`)
      input.setAttribute('type', `hidden`)
      input.value = i
      this.idsDivTarget.appendChild(input)
    })
    let remote = this.formTarget.dataset.remote
    this.formTarget.setAttribute('action', exportUrl)
    this.formTarget.dataset.remote = false
    this.formButtonTarget.click()
    if (remote == true) {
      this.formTarget.dataset.remote = true
    }

    const ids_list = this.idsDivTarget

    while (ids_list.hasChildNodes()) {
      ids_list.removeChild(ids_list.firstChild)
    }
    this.formTarget.setAttribute('action', originalUrl)
  }
}
