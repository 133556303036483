import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  delete(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const path = event.target.getAttribute('href')

    if(!path) {
      return false
    }

    let data = {}

    data['locker_ids'] = this.getLockerIds()
    
    if(data['locker_ids'] === undefined || data['locker_ids'].length <= 0) {
      alert('Please select a locker')
      return
    }

    data['_method'] = 'delete'

    alert(`You are going to delete ${data['locker_ids'].length} lockers.`)
    $.ajax({
      type: 'POST',
      url: path,
      data: data
    })
  }

  reset_allocation(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const path = event.target.getAttribute("href")

    if(!path) {
      return false
    }

    let data = {}

    data["locker_ids"] = this.getLockerIds()

    if(data["locker_ids"] === undefined || data["locker_ids"].length <= 0) {
      alert("Please select a locker")
      return
    }

    data["_method"] = "post"

    alert(`You are going to reset ${data["locker_ids"].length} lockers.`)
    $.ajax({
      type: "POST",
      url: path,
      data: data
    })
  }

  getLockerIds() {
    const $scope = this.context.scope.element
    const $selectedLockers = $scope.querySelectorAll('input[type=checkbox]:checked')
    return Array(...$selectedLockers).map(($input) => {
      return $input.value
    })
  }
}
