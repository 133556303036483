import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['data']

  connect() {
    const dateData  = JSON.parse(this.dataTarget.dataset.occupancyDate)
    const valueData = JSON.parse(this.dataTarget.dataset.occupancyValue)
    var chart = new Chartist.Line('.ct-chart--locker', {
      labels: dateData,
      series: [
        valueData
      ]
    }, {
      low: 0,
      showArea: true
    });
  }
}
