import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  openModal(event) {
    const url = this.element.dataset.url.split('?')
    const elements = document.querySelectorAll(this.element.dataset.checkBoxes)
    let lockerIds = []
    let params = new URLSearchParams(url.pop())

    for(const element of elements) lockerIds.push(element.value)
    if (params.has('locker_ids')) params.delete('locker_ids')
    params.append('locker_ids', [lockerIds])
    $(this.element).data('url', `${url[0]}?${decodeURIComponent(params.toString())}`)
  }
}
