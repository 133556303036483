import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'formButton', 'filterDiv', 'limitDiv', 'paginationPer']

  connect() {
    this.paginationPerTargets.forEach(el => {
      $(el).on("select2:select", function () {
        let event = new Event("change") // fire a native event
        this.dispatchEvent(event);
      });
    })

  }

  load(event) {
    const element = event.target
    const queries = JSON.parse(element.dataset.q)
    this.filterDivTarget.innerHTML = ''
    Object.keys(queries).forEach(key => {
      if (typeof(queries[key]) === 'object') {
        queries[key].forEach(val => {
          this._appendChild(val, key)
        })
      } else {
        this._appendChild(queries[key], key)
      }
    })
    this.formTarget.dataset.remote = true
    this.formButtonTarget.click()
    this.formTarget.dataset.remote = false
  }

  paginationLimitChanged(event) {
    this.limitDivTarget.innerHTML = ''

    const input = document.createElement('input')
    input.setAttribute('name', 'per')
    input.setAttribute('type', 'hidden')
    input.value = event.target.value

    this.limitDivTarget.appendChild(input)
    this.formButtonTarget.click()
  }

  _appendChild(value, key) {
    let input = document.createElement('input')
    input.setAttribute('name', `q[${key}]`)
    input.setAttribute('type', `hidden`)
    input.value = value
    this.filterDivTarget.appendChild(input)
  }
}
