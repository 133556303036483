import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectLockerTable', 'confirmDeployForm']

  showConfirmation(e) {
    const lockerId = e.target.dataset['lockerId']
    const lockerNo = e.target.dataset['lockerNo']
    $(this.confirmDeployFormTarget).find('#collect_asset_locker_id').val(lockerId)
    $(this.confirmDeployFormTarget).find('#deploy-locker-no').html(lockerNo)

    $(this.selectLockerTableTarget).addClass('d-none')
    $(this.confirmDeployFormTarget).removeClass('d-none')
  }

  showSelectLocker(e) {
    $(this.confirmDeployFormTarget).find('#collect_asset_locker_id').val('')
    $(this.confirmDeployFormTarget).find('#deploy-locker-no').html('')

    $(this.selectLockerTableTarget).removeClass('d-none')
    $(this.confirmDeployFormTarget).addClass('d-none')
  }
}
