import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'faultReason',
    'orderCollection'
  ]

  specifyAssetCondition(e) {
    if (e.target.value === 'faulty') {
      this.faultReasonTarget.classList.remove('d-none')
      if (this.orderCollectionTargets.length > 0) { this.orderCollectionTarget.classList.add('d-none') }
    } else {
      this.faultReasonTarget.classList.add('d-none')
      if (this.orderCollectionTargets.length > 0) { this.orderCollectionTarget.classList.remove('d-none') }
    }
  }
}