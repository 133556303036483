import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['confirmResendDiv', 'sendLink', 'confirmationModal']
  connect() {
    $(this.confirmationModalTarget).on('shown.bs.modal', this.confirm.bind(this))

  }

  confirm() {
    this.confirmResendDivTarget.innerHTML = ''
    const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`
    const checked = this.element.querySelectorAll('tbody input[type=checkbox]:checked.form-check-input')
    const object = this.confirmResendDivTarget.dataset.object
    const objectPlural = this.confirmResendDivTarget.dataset.objectPlural
    if (checked.length < 1) {
      this.confirmResendDivTarget.innerHTML = `Please select at least 1 ${object}`
      this.sendLinkTarget.classList.add('d-none')
    } else {
      if(objectPlural == undefined || checked.length === 1) {
        this.confirmResendDivTarget.innerHTML = `You are about to send instructions to ${pluralize(checked.length, object)}, continue?`
      } else {
        this.confirmResendDivTarget.innerHTML = `You are about to send instructions to ${checked.length} ${objectPlural}, continue?`
      }
      this.sendLinkTarget.classList.remove('d-none')

      let params = this.sendLinkTarget.dataset.originPath.split('?')
      let path   = params.shift()

      var tab = ""
      if ($('.nav a.active').attr('href') != undefined) {
        var tab = $('.nav a.active').attr('href').split('#')[1]
      }

      params.push('tab='+ tab)
      checked.forEach(el => params.push(`ids[]=${el.value}`))
      this.sendLinkTarget.setAttribute('href', `${path}?${params.join('&')}`)
    }
  }
}
