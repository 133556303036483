import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['order', 'link', 'form']

  connect() {
    if (this.orderTargets.length === 1) this.linkTargets.forEach(el => el.classList.add('d-none'))
  }

  next(e) {
    const nextElIndex = this._fetchCurrentTrIndex() + 1
    let nextEl = this.orderTargets[nextElIndex]
    if (nextEl === undefined) nextEl = this.orderTargets[0]
    let url = e.target.dataset['url']
    $(this.formTarget).load(url.replace(':locker_id', nextEl.dataset['lockerId']), () => {})
  }

  prev(e) {
    const prevElIndex = this._fetchCurrentTrIndex() - 1
    let prevEl = this.orderTargets[prevElIndex]
    if (prevEl === undefined) prevEl = this.orderTargets[this.orderTargets.length - 1]
    let url = e.target.dataset['url']
    $(this.formTarget).load(url.replace(':locker_id', prevEl.dataset['lockerId']), () => {})
  }

  _fetchCurrentTrIndex() {
    const currentLockerId = $(this.formTarget).find('#locker_id').val()
    const currentTrElement = document.getElementById(`locker_${currentLockerId}`)
    return this.orderTargets.findIndex(el => el === currentTrElement)
  }
}
